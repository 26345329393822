import React from 'react';
import Container from '../../../grid/container';
import DotationInformation from '../../../shared/modal/dotationInformation';
import Col from '../../../grid/col';
import Row from '../../../grid/row';
import Button from '../../../shared/button';
import ActivityRuleSelect from './ActivityRuleSelect';
import ActivityReasonSelect from './ActivityReasonSelect';
import ActivityComment from './ActivityComment';
import { STEP } from '../../../../types/modalTypes';
import { TagActivityReason } from 'georges-types-kit';
var StepMain = function (props) {
    var isValid = function () {
        var valid = props.rule !== null && props.reason !== null;
        if (props.reason === TagActivityReason.GEORGES_DECISION) {
            return valid && props.comment !== null && props.comment.trim() !== '';
        }
        return valid;
    };
    return (React.createElement(Container, { className: "rform" },
        React.createElement(DotationInformation, { data: props.data, label: "dotationToInactive" }),
        React.createElement(ActivityRuleSelect, { setRule: props.setRule }),
        React.createElement(ActivityReasonSelect, { setReason: props.setReason }),
        props.reason === TagActivityReason.GEORGES_DECISION && (React.createElement(ActivityComment, { comment: props.comment, setComment: props.setComment })),
        React.createElement(Row, { className: "justify-content-center" },
            React.createElement(Col, { sm: 5 },
                React.createElement(Button, { color: "red", tradKey: "cancel", onClick: function () { return document.dispatchEvent(new CustomEvent('onReactPoppinClose')); }, show: true })),
            React.createElement(Col, { sm: 5 },
                React.createElement(Button, { color: "green", tradKey: "validate", onClick: function () { return props.setCurrentStep(STEP.LOADING); }, show: isValid() })))));
};
export default StepMain;
